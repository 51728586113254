@mixin centered
  margin-left: auto
  margin-right: auto
  float: none

$m850-grid: (columns: 12, gutter: 20px, media: 850px)

html
  font-family: system-ui
  font-size: responsive 16px 20px
  font-range: 420px 2100px
  height: 100%

body
  background: #B5E3F5
  height: 100%
  display: flex
  flex-direction: column

.header-screen
  background: #B5E3F5
  padding: 50px 0
  flex: 1 0 auto

.container
  @include grid-container
  margin: 0 auto
  max-width: 80rem
  position: relative

.main-row
  @include grid-container
  .poster-column
    @include grid-column
    @include grid-media($m850-grid)
      @include grid-column(6)
    img
      margin-left: auto
      margin-right: auto
      display: block
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
      @include grid-media($m850-grid)
        margin-left: 0
        margin-right: 0
        float: right
  .book-column
    @include grid-column
    @include grid-media($m850-grid)
      @include grid-column(6)
    .title-column
      @include grid-container
      .title-row
        @include grid-column
        h1
          color: #325260
          margin: 2rem 0 0.6rem 0
          font-size: calc(2rem + 1.4 * ( (100vw - 420px) / 860))
          text-align: center
          @include grid-media($m850-grid)
            text-align: left
        h2
          color: #395F6F
          margin: 0
          font-size: calc(1.4rem + 0.6 * ( (100vw - 420px) / 860))
          text-align: center
          font-weight: normal
          @include grid-media($m850-grid)
            text-align: left

    .lead-column
      @include grid-container
      .lead-row
        @include grid-column
        p
          color: #3d4244
          font-size: calc(0.9rem + 0.2 * ( (100vw - 420px) / 860))
          line-height: 1.4
          margin: 1rem 0 2rem 0
          text-rendering: optimizeLegibility
          @include grid-media($m850-grid)
            margin: 1rem 1rem 2rem 0

    .buttons-column
      @include grid-container
      .buttons-row
        @include grid-column
        @include grid-media($m850-grid)
          @include grid-column(6)
        .button
          background: #FFF
          color: #008cc1
          cursor: pointer
          display: inline-block
          padding: 1rem 0
          width: 100%
          border: 1px solid #FFF
          border-radius: 30px
          position: relative
          text-decoration: none
          text-align: center
          margin-bottom: 1rem
          &:hover
            border: 1px solid #008cc1
            background: #008cc1
            color: #FFF


.footer-screen
  background: #545457
  flex-shrink: 0

.footer-column
  @include grid-container
  .footer-contact-links
    @include grid-column(6)
    @include grid-media($m850-grid)
      @include grid-column(4)
    ul
      list-style: none
      overflow: hidden
      font-size: 1rem
      li
        margin: 0 1rem
        list-style: none
        float: left
        display: block
        a
          color: #FFF
          &:hover
            color: #b5e3f5
            text-decoration: none
  .footer-share-links
    display: none
    @include grid-media($m850-grid)
      display: block
      @include grid-column(4)
    div.addthis_toolbox
      height: 35px
      margin-top: 15px
  .footer-copyright
    @include grid-column(6)
    @include grid-media($m850-grid)
      @include grid-column(4)
    font-size: 1rem
    color: #FFF
    margin: 15px 0 10px 0
    .right-author
      float: right
      margin-left: 20px
      font-size: 0.8rem
    .right-license
      float: right


// Images begin
@media screen and (min-width: 420px)
  .main-row
    .poster-column
      img
        width: 400px
        height: 566px

@media screen and (max-width: 420px)
  .main-row
    .poster-column
      img
        max-height: 100%
        max-width: 100%
// Images end

// Responsive font size end
// Utils
@media screen and (max-width: 850px)
  .reviews-on-large
    display: none

#_atssh
  display: none
